import { Button, Field, Form, Link, Typography } from "components";
import { useRouter } from "next/navigation";
import PropTypes from "prop-types";

function Login({ form, loading }) {
  const { push } = useRouter();

  return (
    <Form
      form={form}
      disabled={loading}
      hidden={form.values.view !== "login"}
      title="Inicia sesión"
    >
      <Field
        form={form}
        label="Correo electrónico"
        message="La dirección de correo electrónico no esta registrada"
        name="email"
        type="email"
        required={true}
      />
      <Field
        form={form}
        label="Contraseña"
        message="La contraseña ingresada es incorrecta"
        name="password"
        type="password"
        required={true}
      />
      <Link onClick={() => form.setValues({ view: "restore" })}>
        ¿Has olvidado la contraseña?
      </Link>
      <Button loading={loading} type="submit" size="large" fullWidth>
        {!loading ? "Iniciar sesión" : "Cargando..."}
      </Button>
      <Typography className="BupForm-link" variant="span">
        ¿No tienes una cuenta?{" "}
        <Link onClick={() => push("/signup")}>Regístrate</Link>
      </Typography>
    </Form>
  );
}

Login.propTypes = {
  form: PropTypes.object.isRequired,
};

export default Login;
